<template>
<!--  v-if="loadingPage==false"-->
  <div class="content profile-content">
    <ProfileNavbar :title="$t('profile.title')"></ProfileNavbar>
    <div class="clearfix">
      <!-- <div class="row"> -->
      <!-- <div class="col-md-12"> -->
      <div class="title-row" v-show="visibleProfileTitle">
        <!-- <router-link tag="a" to="/settings" class="title-row-btn"> -->
        <router-link tag="a" to="/profile" class="title-row-btn d-none">
          <img src="/images/profile/nottific.svg">
        </router-link>
        <h3 class="mob-menu-title m-0 p-0 text-center">{{ $t('profile.title') }}</h3>
        <button class="title-row-btn  d-none"
                v-on:click="showButtonsEdit(false, false, true, false, true, false, true, false, false, false, false, false, false, false)">
          <img src="/images/profile/edit.svg" alt="">
        </button>
      </div>

      <div class="mob-menu-title" v-show="visibleEditProfile">
        <button class="mob-menu-back"
                v-on:click="showButtonsEdit(true, true, false, false, false, true, false, false, false, false, false, false, false, false, false)">
          <img src="images/profile/arrow-back.svg">
        </button>
        {{ $t('profile.edit-profile') }}
      </div>

      <div class="mob-menu-title" v-show="visibleEdit">
        <button class="mob-menu-back"
                v-on:click="showButtonsEdit(true, false, false, false, true, true, false, false, false, false, false, false, false, false)">
          <img src="images/profile/arrow-back.svg">
        </button>
        {{ $t('profile.edit-profile') }}
      </div>

      <div class="profile-edit-form w-100">
        <h3 class="title text-center text-lg-left">{{ $t('profile.title') }}</h3>
        <p class="hint text-center text-lg-left">{{ $t('profile.description') }}</p>
        <form @submit.prevent="updateProfile()">
          <div class="row profile-form-block">
            <div class="col-md-12 profile-photo-block">
              <div class="form-group">
                <label class="text-center avatar-label">{{ $t('profile.avatar') }}</label>
                <el-upload
                    class="avatar-uploader"
                    :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                    :action="apiRoot + '/api/avatar/update'"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                  <img v-if="avatar" :src="avatar" class="avatar">
                  <div class="user-info" v-show="visibleProfileInfo">
                    <div class="user-name">{{ account.first_name + ' ' + account.last_name }}</div>
                    <!--                    <div class="user-role">Участник</div>-->
                    <div class="user-desc">
                    </div>
                  </div>
                  <!-- <div class="btn-wrap-desktop">
                      <button type="button" class="btn btn-light">
                      <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70711 6.20711C3.31658 6.59763 2.68342 6.59763 2.29289 6.20711C1.90237 5.81658 1.90237 5.18342 2.29289 4.79289L6.2929 0.79289C6.6834 0.40237 7.3166 0.40237 7.7071 0.79289L11.7071 4.79289C12.0976 5.18342 12.0976 5.81658 11.7071 6.20711C11.3166 6.59763 10.6834 6.59763 10.2929 6.20711L8 3.91421V8.5H6V3.91421L3.70711 6.20711ZM6 8.5H3C1.34315 8.5 0 9.8431 0 11.5V17.5C0 19.1569 1.34315 20.5 3 20.5H11C12.6569 20.5 14 19.1569 14 17.5V11.5C14 9.8431 12.6569 8.5 11 8.5H8V13.5C8 14.0523 7.5523 14.5 7 14.5C6.4477 14.5 6 14.0523 6 13.5V8.5Z" fill="#04C2F4"/>
                      </svg>
                      <span>{{ $t('profile.download-photo') }}</span>
                      </button>
                  </div> -->
                  <div class="btn-wrap" v-show="visiblePhotoDownload">
                    <button type="button" class="btn btn-light">
                      <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.70711 6.20711C3.31658 6.59763 2.68342 6.59763 2.29289 6.20711C1.90237 5.81658 1.90237 5.18342 2.29289 4.79289L6.2929 0.79289C6.6834 0.40237 7.3166 0.40237 7.7071 0.79289L11.7071 4.79289C12.0976 5.18342 12.0976 5.81658 11.7071 6.20711C11.3166 6.59763 10.6834 6.59763 10.2929 6.20711L8 3.91421V8.5H6V3.91421L3.70711 6.20711ZM6 8.5H3C1.34315 8.5 0 9.8431 0 11.5V17.5C0 19.1569 1.34315 20.5 3 20.5H11C12.6569 20.5 14 19.1569 14 17.5V11.5C14 9.8431 12.6569 8.5 11 8.5H8V13.5C8 14.0523 7.5523 14.5 7 14.5C6.4477 14.5 6 14.0523 6 13.5V8.5Z"
                              fill="#04C2F4"/>
                      </svg>
                      <span>{{ $t('profile.download-photo') }}</span>
                    </button>
                  </div>
                  <div v-show="visibleMainTitle" class="mx-auto">
                    <div class="form-sub-title-mob">{{ $t('profile.main') }}</div>
                  </div>
                  <div v-show="visibleEducationTitle" class="mx-auto">
                    <div class="form-sub-title-mob">{{ $t('profile.education') }}</div>
                  </div>
                  <div v-show="visibleParentTitle" class="mx-auto">
                    <div class="form-sub-title-mob">{{ $t('profile.representative') }}</div>
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 profile-main-info" v-show="visibleMain">
              <div class="form-group first-el-mob">
                <label class="form-label">{{ $t('profile.iin-label') }}</label>
                <el-input disabled :placeholder="$t('profile.iin-placeholder')" v-model="account.iin"></el-input>
              </div>
              <form-group>
                <label class="form-label">{{ $t('profile.last-name-label') }}</label>
                <el-input disabled :placeholder="$t('profile.last-name-placeholder')"
                          v-model="account.last_name"></el-input>
              </form-group>
              <form-group>
                <label class="form-label">{{ $t('profile.first-name-label') }}</label>
                <el-input disabled :placeholder="$t('profile.first-name-placeholder')"
                          v-model="account.first_name"></el-input>
              </form-group>
              <form-group>
                <label class="form-label">{{ $t('profile.patronymic-label') }}</label>
                <el-input disabled :placeholder="$t('profile.patronymic-placeholder')"
                          v-model="account.patronymic"></el-input>
              </form-group>
              <form-group>
                <label disabled class="form-label">{{ $t('profile.birth-date-label') }}</label>
                <el-date-picker
                    style="width: 100%;"
                    v-model="account.birth_date"
                    type="date"
                    placeholder="Дата"
                    format="yyyy.MM.dd"
                    default-value="2007.01.01"
                    :picker-options="pickerOptions"
                    value-format="dd.MM.yyyy"
                    :disabled="true">
                </el-date-picker>
              </form-group>
              <form-group>
                <label class="form-label">{{ $t('references.gender') }}</label>
                <el-select
                    :placeholder="$t('references.gender')"
                    v-model="account.sex"
                    :disabled="true">
                  <el-option
                      :key="1"
                      :value="1"
                      :label="$t('references.gender-male')">
                  </el-option>
                  <el-option
                      :key="2"
                      :value="2"
                      :label="$t('references.gender-female')">
                  </el-option>
                </el-select>
              </form-group>

              <form-group
                          :label="$t('reset-password.email-label')"
                          name="email"
                          :class="{'text-danger': $v.account.email.$invalid}">
                <el-input
                    :class="{'form-control-is-invalid': $v.account.email.$invalid}"
                    id="email"
                    @input="emailValidate"
                    :placeholder="$t('reset-password.email-placeholder')"
                    v-model="account.email"
                    clearable>
                </el-input>
                <p class="help-block text-danger" v-if="$v.account.email.$invalid">{{ $t('form-group.email-required') }}</p>
              </form-group >

              <form-group>
                <label class="form-label">{{ $t('profile.representative-tel-label') }}</label>
                <el-input
                    disabled
                    v-mask="'+7 (###) ### ##-##'"
                    @input="enforcePhoneFormat"
                    :placeholder="$t('profile.representative-tel-placeholder')"
                    v-model="phone"
                ></el-input>
              </form-group>
            </div>
            <!-- 2 COLUMN -->
            <div class="col-lg-4 col-md-12 profile-education-info">
              <div v-show="visibleMain">



              </div>
              <h4 class="form-sub-title">{{ $t('profile.education') }}</h4>
              <div v-show="visibleEducation">
                <form-group :field="$v.education.region_id" :class="{'text-danger': $v.education.region_id.$invalid && this.send==true} "  :label="$t('references.required-field') + $t('references.region-label')"  class="first-el-mob"                                                          >

                  <el-select
                      :class="{'form-control-is-invalid': $v.education.region_id.$invalid && this.send==true}"
                      :placeholder="$t('references.region-placeholder')"
                      v-model="education.region_id"
                      :loading="regionsLoadingEducation"
                      @change="getCitiesEducation"
                      filterable>
                    <el-option
                        v-for="item in regionsEducation"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </form-group>
                <form-group  :field="$v.education.city_id" :class="{'text-danger': $v.education.city_id.$invalid && this.send==true} "  :label="$t('references.required-field') + $t('references.district-label')"  >
                  <el-select
                      :class="{'form-control-is-invalid': $v.education.city_id.$invalid && this.send==true}"
                      :placeholder="$t('references.district-placeholder')"
                      v-model="education.city_id"
                      :loading="citiesLoadingEducation"
                      @change="getLocalitiesEducation"
                      filterable>
                    <el-option
                        v-for="item in citiesEducation"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </form-group>
                <form-group  :field="$v.education.locality_id" :class="{'text-danger': $v.education.locality_id.$invalid && this.send==true} "  :label="$t('references.required-field') + $t('references.locality-label')" >
                  <el-select
                      :class="{'form-control-is-invalid': $v.education.locality_id.$invalid && this.send==true}"
                      :placeholder="$t('references.locality-placeholder')"
                      v-model="education.locality_id"
                      :loading="localitiesLoadingEducation"
                      @change="getSchools"
                      filterable>
                    <el-option
                        v-for="item in localitiesEducation"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </form-group>
                <form-group :field="$v.education.school_id" :class="{'text-danger': $v.education.school_id.$invalid && this.send==true} "  :label="$t('references.required-field') + $t('references.school-name-label')"  >
                  <el-select
                      :class="{'form-control-is-invalid': $v.education.school_id.$invalid && this.send==true}"
                      :placeholder="$t('references.school-name-placeholder')"
                      v-model="education.school_id"
                      :loading="localitiesLoadingEducation"
                      filterable>
                    <el-option
                        v-for="item in schools"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </form-group>
              </div>
            </div>
            <!-- 3 COLUMN -->
            <div class="col-lg-4 col-md-12 profile-parent-info">
              <div v-show="visibleEducation">
                <form-group :field="$v.education.class" :class="{'text-danger': $v.education.class.$invalid && this.send==true} "      :label="$t('references.required-field') + $t('references.class-label')"  >
                  <el-select
                      :class="{'form-control-is-invalid': $v.education.class.$invalid && this.send==true}"
                      v-model="education.class" :placeholder="$t('references.class-placeholder')">
                    <el-option
                        v-for="item in classes"
                        :key="item.id"
                        :label="item.id + $t('profile.class')"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </form-group>

                <form-group :field="$v.education.instruction_language" :class="{'text-danger': $v.education.instruction_language.$invalid && this.send==true} "      :label="$t('references.required-field') + $t('references.education-language-label')"  >

                  <el-select
                      :class="{'form-control-is-invalid': $v.education.instruction_language.$invalid && this.send==true}"
                      v-model="education.instruction_language"
                             :placeholder="$t('references.education-language-placeholder')">
                    <el-option
                        v-for="item in [{
                                                label: $t('references.language-ru'),
                                                value: 'ru'
                                                }, {
                                                label: $t('references.language-kz'),
                                                value: 'kz'
                                                }, {
                                                label: $t('references.language-en'),
                                                value: 'en'
                                                }]"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </form-group>
                <form-group :field="$v.education.test_language" :class="{'text-danger': $v.education.test_language.$invalid && this.send==true} "      :label="$t('references.required-field') + $t('references.test-language-label')"  >

                  <el-select
                      :class="{'form-control-is-invalid': $v.education.test_language.$invalid && this.send==true}"
                      v-model="education.test_language"
                             :placeholder="$t('references.test-language-label')">
                    <el-option
                        v-for="item in [{
                                                label: $t('references.language-ru'),
                                                value: 'ru'
                                                }, {
                                                label: $t('references.language-kz'),
                                                value: 'kz'
                                                }]"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </form-group>
              </div>
              <h4 class="form-sub-title">{{ $t('profile.representative') }}</h4>
              <div v-show="visibleParent">
                <form-group       :label=" $t('profile.representative-last-name-label')"  class="first-el-mob">
                  <el-input

                      :placeholder="$t('profile.representative-last-name-placeholder')"
                            v-model="parent.last_name">
                  </el-input>
                </form-group>
                <form-group        :label="$t('profile.representative-first-name-label')">
                  <el-input

                      :placeholder="$t('profile.representative-first-name-placeholder')"
                            v-model="parent.first_name">
                  </el-input>
                </form-group>
                <form-group>
                  <label class="form-label">{{  $t('profile.representative-patronymic-label') }}</label>
                  <el-input :placeholder="$t('profile.representative-patronymic-placeholder')"
                            v-model="parent.patronymic"></el-input>
                </form-group>



                <form-group
                            :label=" $t('profile.representative-tel-label')"
                            name="parentPhone"
                           >
                  <el-input
                      v-mask="'+7 (###) ### ##-##'"
                      @input="enforceParentPhoneFormat"

                      id="parentPhone"
                      :placeholder="$t('profile.representative-tel-placeholder')"
                      v-model="parentPhone"
                      clearable>
                  </el-input>
                </form-group>


              </div>
            </div>
          </div>
          <div class="row my-4 profile-save" v-show="visibleEditProfileSave">
            <button type="submit" class="btn btn-primary mx-auto">{{ $t('profile.save-btn') }}</button>
          </div>

          <b-modal id="modal-to-survey" hide-footer hide-header centered>
            <button class="btn-close-top" @click="$bvModal.hide('modal-to-survey')">
              <img width="12" src="/images/profile/close.svg" alt="">
            </button>
            <img width="208" src="/images/profile/survey-img.svg" alt="">
            <p class="modal-to-survey-title my-4">{{ $t('profile.modal-title') }}</p>
            <p class="modal-to-survey-desc">{{ $t('profile.modal-desc-1') }} <br>{{ $t('profile.modal-desc-2') }} </p>
            <div class="d-flex flex-column modal-to-survey-btns">
              <router-link tag="button" to="/surveys" class="modal-close mb-4">{{
                  $t('profile.btn-to-survey')
                }}
              </router-link>
              <button class="modal-close modal-close--light" @click="$bvModal.hide('modal-to-survey')">
                {{ $t('profile.btn-dont-show') }}
              </button>
            </div>
          </b-modal>
        </form>
      </div>
      <div class="more-info-btns d-none" v-show="visibleBtnsProfile">
        <router-link tag="a" to="/results" class="btn more-info-btn d-none">
          <img src="/images/profile/mob-results.svg" class="mr-4">
          {{ $t('sidebar.results') }}
        </router-link>
        <router-link tag="a" to="/parent" class="btn more-info-btn d-none">
          <img src="/images/profile/mob-parent.svg" class="mr-4">
          {{ $t('sidebar.parent') }}
        </router-link>
        <router-link tag="a" to="/surveys" class="btn more-info-btn d-none">
          <img src="/images/profile/mob-surveys.svg" class="mr-4">
          {{ $t('sidebar.surveys') }}
        </router-link>
      </div>

      <div class="more-info-btns" v-show="visibleBtnsEditProfile">
        <button class="btn more-info-btn"
                v-on:click="showButtonsEdit(false, false, false, true, false, false, true, true, false, false, true, false, false, true)">
          <img src="/images/profile/mob-profile.svg" class="mr-4">
          {{ $t('profile.main') }}
        </button>
        <button class="btn more-info-btn"
                v-on:click="showButtonsEdit(false, false, false, true, false, false, true, false, false, true, false, false, true, true)">
          <img src="/images/profile/mob-parent.svg" class="mr-4">
          {{ $t('profile.representative') }}
        </button>
        <button class="btn more-info-btn"
                v-on:click="showButtonsEdit(false, false, false, true, false, false, true, false, true, false, false, true, false, true)">
          <img src="/images/profile/mob-education.svg" class="mr-4">
          {{ $t('profile.education') }}
        </button>
      </div>
      <!-- </div> -->
      <!-- </div> -->
    </div>
    <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.error-modal') }}</h3>
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions} from "vuex";
import ProfileNavbar from "@/components/ProfileNavbar";
import VueMask from 'v-mask';
import {required, minLength, email} from 'vuelidate/lib/validators'

export default {
  name: 'Account',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let startDate = new Date();
          startDate.setMonth(11, 31);
          startDate.setFullYear(startDate.getFullYear() - 15);

          let endDate = new Date();
          endDate.setMonth(11, 31);
          endDate.setFullYear(endDate.getFullYear() - 9);

          return time.getTime() < startDate || time.getTime() >= endDate;
        }
      },
      emailCopy:'',
      errorMessage:'',
      send:false,
      loadingPage: true,
      loading: false,
      visibleProfileTitle: true,
      visibleBtnsProfile: true,
      visibleEditProfile: false,
      visibleEdit: false,
      visibleBtnsEditProfile: true,
      visibleProfileInfo: true,
      visiblePhotoDownload: false,
      visibleMain: true,
      visibleEducation: true,
      visibleParent: true,
      visibleMainTitle: false,
      visibleEducationTitle: false,
      visibleParentTitle: false,
      visibleEditProfileSave: false,
      emailCorrect:true,
      phone: '',
      parentPhone: '',
      additionalPhone: '',
      apiRoot: window.API_ROOT,
      avatarUrl: '',
      avatar: '',
      password: '',
      passwordConfirm: '',
      regions: [],
      cities: [],
      localities: [],
      schools: [],
      regionsLoading: false,
      schoolsLoading: false,
      citiesLoading: false,
      localitiesLoading: false,
      regionsEducation: [],
      citiesEducation: [],
      localitiesEducation: [],
      schoolsEducation: [],
      regionsLoadingEducation: false,
      schoolsLoadingEducation: false,
      citiesLoadingEducation: false,
      localitiesLoadingEducation: false,
      classes: [
        {
          id: 3,
        },
          {
          id: 4,
        },
          {
          id: 5,
        },
        {
          id: 6,
        },
        {
          id: 7,
        },
      ],

      account: {
        iin: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        region_id: '',
        city_id: '',
        locality_id: '',
        internet_connection: '',
        birth_date: '',
        sex: '',
        interface_lang: localStorage.locale,

      },
      parent: {
        additional_phone: '',
        created_at: '',
        email: '',
        first_name: '',
        id: '',
        last_name: '',
        patronymic: '',
        phone: '',
        updated_at: '',
        user_id: '',
      },
      education: {
        city_id: '',
        class: '',
        class_for_admission: '',
        created_at: null,
        id: '',
        instruction_language: '',
        locality_id: '',
        region_id: '',
        school_id: '',
        school_type_id: '',
        score: "120.00",
        test_language: '',
        updated_at: '',
        user_id: ''
      }
    }
  },
  methods: {
    emailValidate(val){
          if (val==''){
            return this.emailCorrect = true;
          }
          if (val!=''){
            this.emailCorrect=false;
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          var emailBool = (re.test(String(val).toLowerCase()))
          if (this.emailCopy != this.account.email) {
            if (emailBool == true) {
              this.$http.get(window.API_ROOT + '/api/check-email?email=' + val).then((res) => {
                // alert(res.body.message)
                if (res.body != false) {
                  if (res.body.message != 'ok') {
                    this.emailCorrect = false;
                    this.errorMessage = res.body.message;
                    this.$bvModal.show('error-modal');
                  } else if (res.body.message == 'ok') {
                    this.emailCorrect = true;

                  }
                }
              })
            } else {
              this.errorMessage = this.$t('form-group.email-required');
            }
          } else {
            this.emailCorrect = true;
          }
          }

      },
    getInfo() {
      this.loadingPage = true
      Vue.use(VueMask);
      let res=JSON.parse(localStorage.getItem('user'))
      if(res){
        if(res.body){
          if(res.body.data){
            res=JSON.parse(localStorage.getItem('user'))
            sessionStorage.setItem('avatar-url', res.body.data.avatar);
            this.avatar = window.API_ROOT + '/api/user/avatar?file=' + res.body.data.avatar;
            this.$http.get(this.avatar)
                .then((res) => {
                  this.loadingPage = true
                  var file = res.body;
                  var reader = new FileReader();
                  reader.readAsDataURL(file);
                  this.avatar.src = reader.result;
                  this.loadingPage = false

                }).catch((e) => {

            });
            this.account = res.body.data;
            if (this.account.patronymic == null) {
              this.account.patronymic = ''
            }

            if(this.account.email == null){
              this.account.email = ''
            }
            if(!this.education.city_id){
              this.getUser()
            }

            this.emailCopy = this.account.email
            this.phone = this.account.phone
            if (res.body.data.parent != null) {
              this.parent = res.body.data.parent
            }
            if (this.parent != null) {
              if (this.parent.last_name == null) {
                this.parent.last_name = ''
              }
              if (this.parent.first_name == null) {
                this.parent.first_name = ''
              }
              if (this.parent.patronymic == null) {
                this.parent.patronymic = ''
              }
              if (this.parent.phone != null) {
                this.parentPhone = this.parent.phone
              } else {
                this.parentPhone = ''
              }
            }
            if (res.body.data.education != null) {
              this.education = res.body.data.education
            }



            this.regionsLoading = true;
            this.$http.get(window.API_ROOT + '/api/references/regions/')
                .then((res) => {
                  this.loadingPage = true
                  if (res.body.data) {
                    this.regions = res.body.data;
                    this.regionsEducation = res.body.data;
                    this.regionsLoading = false;
                  }
                  this.loadingPage = false
                }).catch((e) => {
              this.regionsLoading = false;
            });
            if (this.education.region_id) {
              this.getCitiesEducation(this.education.region_id, false);
            }
            if (this.education.city_id) {
              this.getLocalitiesEducation(this.education.city_id, false);
            }
            if (this.education.locality_id) {
              this.$http.get(window.API_ROOT + '/api/references/schools/' + this.education.locality_id)
                  .then((res) => {
                    this.loadingPage = true
                    if (res.body.data) {
                      this.schools = res.body.data;
                    }
                    this.loadingPage = false
                  }).catch((e) => {
              });
            }
            this.loadingPage = false
          }
          else{
            this.getUser()
          }
        }
        else{
          this.getUser()
        }
      }
      else{
        this.getUser()
      }

    },
    getUser(){
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            sessionStorage.setItem('avatar-url', res.body.data.avatar);
            this.avatar = window.API_ROOT + '/api/user/avatar?file=' + res.body.data.avatar;
            this.$http.get(this.avatar)
                .then((res) => {
                  this.loadingPage = true
                  var file = res.body;
                  var reader = new FileReader();
                  reader.readAsDataURL(file);
                  this.avatar.src = reader.result;
                  this.loadingPage = false

                }).catch((e) => {

            });
            this.account = res.body.data;
            if (this.account.patronymic == null) {
              this.account.patronymic = ''
            }

            if(this.account.email == null){
              this.account.email = ''
            }

            this.emailCopy = this.account.email
            this.phone = this.account.phone
            if (res.body.data.parent != null) {
              this.parent = res.body.data.parent
            }
            if (this.parent != null) {
              if (this.parent.last_name == null) {
                this.parent.last_name = ''
              }
              if (this.parent.first_name == null) {
                this.parent.first_name = ''
              }
              if (this.parent.patronymic == null) {
                this.parent.patronymic = ''
              }
              if (this.parent.phone != null) {
                this.parentPhone = this.parent.phone
              } else {
                this.parentPhone = ''
              }
            }
            if (res.body.data.education != null) {
              this.education = res.body.data.education
            }


            this.regionsLoading = true;
            this.$http.get(window.API_ROOT + '/api/references/regions/')
                .then((res) => {
                  this.loadingPage = true
                  if (res.body.data) {
                    this.regions = res.body.data;
                    this.regionsEducation = res.body.data;
                    this.regionsLoading = false;
                  }
                  this.loadingPage = false
                }).catch((e) => {
              this.regionsLoading = false;
            });
            if (this.education.region_id) {
              this.getCitiesEducation(this.education.region_id, false);
            }
            if (this.education.city_id) {
              this.getLocalitiesEducation(this.education.city_id, false);
            }
            if (this.education.locality_id) {
              this.$http.get(window.API_ROOT + '/api/references/schools/' + this.education.locality_id)
                  .then((res) => {
                    this.loadingPage = true
                    if (res.body.data) {
                      this.schools = res.body.data;
                    }
                    this.loadingPage = false
                  }).catch((e) => {
              });
            }
            this.loadingPage = false

          })
    },
    updateProfile() {
      if(this.emailCorrect==true){
      this.send=true
      var tab

      if ($('body').width() >= 992) {
        tab=undefined
      }
      if ($('body').width() <= 992) {
        if(this.visibleMain == true){
          tab=1
        }
        if(this.visibleEducationn == true){
          tab=2
        }
        if(this.visibleParent == true){
          tab=3
        }

      }

      if(this.education.instruction_language==null){
        this.education.instruction_language=''
      }
        if(this.education.test_language==null){
          this.education.test_language=''
        }
      if(this.account.email==null){
        this.account.email = ''
      }
      if(this.account.patronymic==null){
        this.account.patronymic=''
      }
      if(this.education.region_id==null){
        this.education.region_id = ''
      }
      if(this.education.locality_id==null){
        this.education.locality_id = ''
      }
      if (this.education.city_id==null){
        this.education.city_id = ''
      }
      if (this.education.school_id == null){
        this.education.school_id = ''
      }
      if(this.education.class == null){
        this.education.class = ''
      }

      switch (tab) {

        case 2:
          if ( !this.$v.education.instruction_language.$invalid &&!this.$v.education.test_language.$invalid &&!this.$v.education.class.$invalid && !this.$v.education.region_id.$invalid && !this.$v.education.city_id.$invalid && !this.$v.education.locality_id.$invalid && !this.$v.education.school_id.$invalid  ) {
            if (this.parent.phone == 7 || this.parent.phone == null) {
              this.parent.phone = ''
            }
            this.$http.post(window.API_ROOT + '/api/user/profile/update?account[iin]=' + this.account.iin + '&account[last_name]=' + this.account.last_name + '&account[first_name]=' + this.account.first_name + '&account[patronymic]=' + this.account.patronymic + '&account[birth_date]=' + this.account.birth_date + '&account[phone]=' + this.account.phone + '&account[email]=' + this.account.email + '&account[sex]=' + this.account.sex  + '&education[region_id]=' + this.education.region_id + '&education[locality_id]=' + this.education.locality_id + '&education[city_id]=' + this.education.city_id + '&education[school_id]=' + this.education.school_id + '&education[class]=' + this.education.class + '&education[instruction_language]=' + this.education.instruction_language + '&education[test_language]=' + this.education.test_language + '&parent[last_name]=' + this.parent.last_name + '&parent[first_name]=' + this.parent.first_name + '&parent[patronymic]=' + this.parent.patronymic + '&parent[phone]=' + this.parent.phone)
                .then((res) => {
                  this.send=false
                  Vue.toastr({
                    message: this.$t('profile.settings.success') + '!',
                    description: this.$t('profile.settings.success'),
                    type: 'success'
                  });
                  this.$http.get(window.API_ROOT + '/api/user/edit')
                      .then((res) => {
                        localStorage.setItem('user', JSON.stringify(res));
                        this.$router.go()
                      })
                }).catch((e) => {

            });
          } else {
            Vue.toastr({
              message: this.$t('profile.settings.error'),
              description: this.$t('profile.settings.profile-error'),
              type: 'error'
            });
          }
          break;
        case 3:
          if (true) {

            if (this.parent.phone == 7 || this.parent.phone == null) {
              this.parent.phone = ''
            }
            this.$http.post(window.API_ROOT + '/api/user/profile/update?account[iin]=' + this.account.iin + '&account[last_name]=' + this.account.last_name + '&account[first_name]=' + this.account.first_name + '&account[patronymic]=' + this.account.patronymic + '&account[birth_date]=' + this.account.birth_date + '&account[phone]=' + this.account.phone + '&account[email]=' + this.account.email + '&account[sex]=' + this.account.sex  + '&education[region_id]=' + this.education.region_id + '&education[locality_id]=' + this.education.locality_id + '&education[city_id]=' + this.education.city_id + '&education[school_id]=' + this.education.school_id + '&education[class]=' + this.education.class + '&education[instruction_language]=' + this.education.instruction_language + '&education[test_language]=' + this.education.test_language + '&parent[last_name]=' + this.parent.last_name + '&parent[first_name]=' + this.parent.first_name + '&parent[patronymic]=' + this.parent.patronymic + '&parent[phone]=' + this.parent.phone)
                .then((res) => {
                  this.send=false
                  Vue.toastr({
                    message: this.$t('profile.settings.success') + '!',
                    description: this.$t('profile.settings.success'),
                    type: 'success'
                  });
                  this.$http.get(window.API_ROOT + '/api/user/edit')
                      .then((res) => {
                        localStorage.setItem('user', JSON.stringify(res));
                        this.$router.go()
                      })
                }).catch((e) => {

            });
          } else {
            Vue.toastr({
              message: this.$t('profile.settings.error'),
              description: this.$t('profile.settings.profile-error'),
              type: 'error'
            });
          }
          break;
        default:
          if ( !this.$v.education.instruction_language.$invalid &&!this.$v.education.test_language.$invalid && this.emailCorrect==true &&  !this.$v.education.class.$invalid && !this.$v.education.region_id.$invalid && !this.$v.education.city_id.$invalid && !this.$v.education.locality_id.$invalid && !this.$v.education.school_id.$invalid  ) {
            if (this.parent.phone == 7 || this.parent.phone == null) {
              this.parent.phone = ''
            }
            this.$http.post(window.API_ROOT + '/api/user/profile/update?account[iin]=' + this.account.iin + '&account[last_name]=' + this.account.last_name + '&account[first_name]=' + this.account.first_name + '&account[patronymic]=' + this.account.patronymic + '&account[birth_date]=' + this.account.birth_date + '&account[phone]=' + this.account.phone + '&account[email]=' + this.account.email + '&account[sex]=' + this.account.sex  + '&education[region_id]=' + this.education.region_id + '&education[locality_id]=' + this.education.locality_id + '&education[city_id]=' + this.education.city_id + '&education[school_id]=' + this.education.school_id + '&education[class]=' + this.education.class + '&education[instruction_language]=' + this.education.instruction_language + '&education[test_language]=' + this.education.test_language + '&parent[last_name]=' + this.parent.last_name + '&parent[first_name]=' + this.parent.first_name + '&parent[patronymic]=' + this.parent.patronymic + '&parent[phone]=' + this.parent.phone)
                .then((res) => {
                  this.send=false
                  Vue.toastr({
                    message: this.$t('profile.settings.success') + '!',
                    description: this.$t('profile.settings.success'),
                    type: 'success'
                  });
                  this.$http.get(window.API_ROOT + '/api/user/edit')
                      .then((res) => {
                        localStorage.setItem('user', JSON.stringify(res));
                        this.$router.go()
                      })
                }).catch((e) => {

            });
          } else {
            Vue.toastr({
              message: this.$t('profile.settings.error'),
              description: this.$t('profile.settings.profile-error'),
              type: 'error'
            });
          }
      }}
      else {
        this.$bvModal.show('error-modal');
      }
    },

    showButtonsEdit(visibleProfileTitle, visibleBtnsProfile, visibleEditProfile, visibleEdit, visibleBtnsEditProfile, visibleProfileInfo, visiblePhotoDownload, visibleMain, visibleEducation, visibleParent, visibleMainTitle, visibleEducationTitle, visibleParentTitle, visibleEditProfileSave) {
      this.visibleProfileTitle = visibleProfileTitle;
      this.visibleBtnsProfile = visibleBtnsProfile;

      this.visibleEditProfile = visibleEditProfile;
      this.visibleBtnsEditProfile = visibleBtnsEditProfile;
      this.visibleEdit = visibleEdit;
      this.visibleProfileInfo = visibleProfileInfo;
      this.visiblePhotoDownload = visiblePhotoDownload;

      this.visibleMain = visibleMain;
      this.visibleEducation = visibleEducation;
      this.visibleParent = visibleParent;

      this.visibleMainTitle = visibleMainTitle;
      this.visibleEducationTitle = visibleEducationTitle;
      this.visibleParentTitle = visibleParentTitle;
      this.visibleEditProfileSave = visibleEditProfileSave;
    },
    //Education
    getCitiesEducation(id, reset = true) {
      this.citiesLoadingEducation = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoadingEducation = false;
            if (res.body.data) {
              this.citiesEducation = res.body.data;
              if (reset == true) {
                this.education.locality_id = '';
                this.education.city_id = '';
                this.education.school_id = ''
              }
            }
          }).catch((e) => {
        this.citiesLoadingEducation = false;
      })
    },
    getLocalitiesEducation(id, reset = true) {
      this.localitiesLoadingEducation = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoadingEducation = false;
            this.localitiesEducation = res.body.data;
            if (reset == true) {
              this.education.locality_id = '';
              this.education.school_id = ''


            }
          }).catch((e) => {
        this.localitiesLoadingEducation = false;
      })
    },
    getSchools(id, reset = true) {
      this.schoolsLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/schools/' + id)
          .then((res) => {
                if (reset == true) {
                  this.schools = '';
                  this.education.school_id = ''
                }
                this.schoolsLoading = false;
                this.schools = res.body.data;
              }
          ).catch((e) => {
        this.schoolsLoading = false;
      })
    },

    getCities(id, reset = true) {
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;


            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    },
    getLocalities(id, reset = true) {
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;


            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    },
    getCitiesAccount(id, reset = true) {
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;
              this.account.locality_id = '';
              this.account.city_id = '';


            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    },
    getLocalitiesAccount(id, reset = true) {
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
              this.account.locality_id = '';

            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    },
    enforcePhoneFormat() {
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.phone = !x[2]
          ? x[1]
          : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");

      this.account.phone = x[0];
    },
    enforceParentPhoneFormat() {
      let x = this.parentPhone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.parentPhone = !x[2]
          ? x[1]
          : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");
      this.parent.phone = x[0];

    },
    handleAvatarSuccess(res, file) {
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res));
            this.$router.go()
            this.avatarUrl = URL.createObjectURL(file.raw);
          })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!(isJPG || isPNG)) {
        this.$message.error('Avatar picture must be JPG or PNG format!');
      }
      if (!isLt10M) {
        this.$message.error('Avatar picture size can not exceed 10MB!');
      }
      return (isJPG || isPNG) && isLt10M;
    },
    logoutMethod() {
      this.logout();
      localStorage.setItem('role', '');
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      sessionStorage.setItem('avatar-url', '')
      window.location = '/';
      // this.$router.push('/');
    },
    ...mapActions({
      'logout': 'logout'
    }),
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  mounted() {
    if(!localStorage.getItem('user')){
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res));
          })
    }
    if(!localStorage.getItem('token')){
      this.$router.push('/')
    }
    if ($('body').width() <= 992) {
      this.visibleMain = false;
      this.visibleEducation = false;
      this.visibleParent = false;
    } else {
      this.visibleMain = true;
      this.visibleEducation = true;
      this.visibleParent = true;
      this.visibleEditProfileSave = true;
      this.visiblePhotoDownload = true;
    }
    this.getInfo()



    if (sessionStorage.getItem('avatar-url')) {
      this.avatarUrl = sessionStorage.getItem('avatar-url');
    }
  },
  validations: {
    account: {
      email: {email},
    },
    parent: {
      last_name: {required},
      first_name: {required},

    },
    parentPhone: {
      required,
      minLength: minLength(18)},
    education:{
      test_language: {required},
      instruction_language: { required},
    class:{
      required
    },
    region_id:{
      required
    },
    city_id:{
      required
    },
    locality_id:{
      required
    },
    school_id:{
      required
    }
    }
  }
}
</script>
<style lang="postcss" scoped>

/deep/ .form-control-is-invalid .el-input__inner {
    border: 1px solid #dc3545 !important;
    color: #dc3545 !important;
}
/deep/ .form-control-is-invalid .el-input__inner:hover {
  border-color: #dc3545 !important;
}
.form-group {
  display: flex;
  flex-direction: column;
}
.avatar-uploader {
    display: flex!important;
}
form .avatar-uploader .btn-wrap {

  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
  overflow: hidden;
  margin-top: 110px;
  height: fit-content;
}

form .avatar-uploader .el-upload {
  display: flex;
}

form .avatar-uploader .btn-light {
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 2px 6px #ccc;
  line-height: 22px;
}

form .avatar-uploader .btn-light svg {
  float: left;
  margin-right: 8px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader .avatar {
  float: left;
  width: 178px;
  height: 178px;
  border-radius: 178px;
  display: block;
  margin-right: 64px;
  object-fit: cover;
}

.back-btn {
  background-color: #04C2F4;
  height: 55px;
  width: 58px;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  left: 15px;
  border: none;
}

.title-profile-mob {
  display: none;
}

.content .profile-edit-form {
  max-width: 1120px;
}
/deep/ .avatar-uploader .el-upload {
    display: flex!important;
}
@media (max-width: 992px) {
  .avatar {
    background-color: white;
  }

  .avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  form .avatar-uploader .btn-wrap {
    width: fit-content;
    margin: 28px auto 31px auto;
  }

  .profile-content {
    padding: 0 0 0 0;
  }

  .title, .form-sub-title {
    display: none;
  }
  /deep/ .avatar-uploader .el-upload {
        flex-direction: column!important;
        margin: 0 auto!important;
  }
  .content .profile-edit-form {
      padding-bottom: 0;
  }
  .user-name {
      padding-top: 6px;
  }
}
@media screen and (max-width: 991px) {
  .el-select-dropdown__item {
    max-width: 350px;
  }
}
</style>
